<template>
  <div class="invite fd-w-full fd-h-full fd-pt-12">
    <div class="fd-flex fd-items-end fd-text-theme-4 fd-px-12">
      <span class="fd-text-3xl fd-font-normal">LAST</span>
      <span class="fd-text-base fd-font-normal">Floors</span>
    </div>
    <div class="card fd-relative fd-w-7/12 fd-mx-auto fd-mt-8 fd-px-14 fd-py-10" style="height: 660px;">
      <router-view />
    </div>
  </div>
</template>

<script>

export default {
  name: "AnaLayoutInvitation",
  data() {
    return {
      currentPage: 'invitations.ana.confirm',
    }
  },
  watch: {
    '$router.name': {
      handler() {
        this.currentPage = this.$route.name
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.invite {
  background: #FAFAFA url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjQ1IiB2aWV3Qm94PSIwIDAgMTAwIDQ1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMTM0Ljg1MyAtNDAuNzUxOEw1MCA0My4xOTkyTC0zNC44NTI4IC00MC43NTE4IiBzdHJva2U9IiNGNEY3RjUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K");

  & ::v-deep label {
    color: #5E5873 !important;
  }

  & ::v-deep svg.arrow-right {
    //height: 18px;
    path {
      stroke: #6E6B7B;
    }
  }

  & ::v-deep span {
    &:not(.done) svg {
      path {
        stroke: #515952;
      }
    }
    &:not(.done).active svg {
      path {
        stroke: #ffffff;
      }
    }
    &.done svg {
      path {
        fill: #515952;
      }
    }
    &.done.active svg {
      path {
        fill: #ffffff;
      }
    }
  }
}
</style>